@import "./App.scss";

.print-character-sheet div.box {
    @extend .mb-1;
}

.print-character-sheet .title {
    font-size: 36px;
    font-weight: bold;
}

.print-character-sheet .skill-category-title {
    @extend .mb-1;
    font-size: 24px;
    font-weight: bold;
}

.print-character-sheet .subcategory-title {
    @extend .mb-1;
    font-size: 18px;
    font-weight: bold;
}

.btn.counter-button {
    @extend .py-0;
    @extend .px-1;
}

.print-character-sheet .skill {
    @extend .border-bottom;
    border-bottom-color: $gray-400 !important;
}

.print-character-sheet .slot-card {
    @extend .border;
    @extend .border-dark;
    @extend .m-1;
    @extend .p-1;
}

.print-character-sheet .bold-skill-name {
    font-weight: bold;
}

#print-character-sheet-page .background-image {
    position: fixed;
    left: 50vw;
    top: 50vh;
    transform: translate(-50%, -50%);
}

@media print {
    #print-character-sheet-page {
        position: relative;
        color: #000;
        background-color: #fff;
        font-family: "Times New Roman",
            Times,
            serif;
    }

    .dont-print {
        display: none;
    }

    .back-button {
        @extend .dont-print;
    }
}