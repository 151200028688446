@import "./App.scss";

div.form-page {
    max-height: 100vh;
}

div.form-page>div[class^='row'] {
    justify-content: center;
}

div.form-page>div[class^='row']>div[class^='col'] {
    @extend .d-flex;
    @extend .flex-column;
    @extend .p-4;
    @extend .rounded;
    @extend .my-4;
    background-color: $gray-500;
    color: #000;
    overflow: hidden;
}

div.form-page form {
    @extend .d-flex;
    @extend .flex-column;
}

div.form-page form div[class^='row'] {
    align-items: center;
}