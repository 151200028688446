@import "./App.scss";

div.home-navbar-content {
  @extend .p-3;
  @extend .rounded;
  @extend .mx-sm-2;
  @extend .my-sm-3;
  max-width: 800;
  flex-grow: 1;
  background-image: url(../../public/eye-background.svg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: $gray-500;
  color: #000;
}

div.home-navbar-content>div.col {
  @extend .d-flex;
  @extend .flex-column;
  gap: 8px;
}

div.home-navbar-container nav.navbar {
  background-color: gray;
}

div.home-navbar-container {
  @extend .d-flex;
  @extend .flex-column;
  height: 100vh;
  max-height: 100vh;
}

.navbar a,
.navbar .behave-like-link {
  @extend .px-3;
  text-decoration-line: none;
  color: #000;
}

.navbar a {
  font-weight: bold;
}

.navbar a:hover {
  color: #000;
}

.navbar {
  color: #000;
}