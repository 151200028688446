@import "./App.scss";

.character-sheet div.box {
    @extend .rounded;
    @extend .p-3;
    @extend .mb-3;
    background-color: $gray-200;
    color: black;
}

.character-sheet .title {
    font-size: 36px;
    font-weight: bold;
}

.character-sheet .skill-category-title {
    @extend .mb-1;
    font-size: 24px;
    font-weight: bold;
}

.character-sheet .subcategory-title {
    @extend .mb-1;
    font-size: 18px;
    font-weight: bold;
}

.btn.counter-button {
    @extend .py-0;
    @extend .px-1;
}

.character-sheet .skill {
    @extend .border-bottom;
    border-bottom-color: $gray-400 !important;
}

.character-sheet .slot-card {
    @extend .border;
    @extend .border-dark;
    @extend .m-1;
    @extend .p-1;
}

.character-sheet .bold-skill-name {
    font-weight: bold;
}

.character-sheet div.top-right-corner-box {
    @extend .shadow;
    @extend .p-3;
    background-color: $gray-500;
    font-weight: 500;
    font-size: 18px;
    color: #000;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1;
}