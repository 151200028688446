@import "./App.scss";

.row.view-accepted-row {
    height: 50vh;
    max-height: 50vh;
}

.col.event-reg-sidebar {
    @extend .ps-0;
    max-width: 300px;
    max-height: 50vh;
    overflow-y: scroll;
}

.col.event-reg-sidebar div.sidebar-nav {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: $gray-500;
}

.col.button-column {
    gap: 8px;
}

.col.event-reg-sidebar div.sidebar-header {
    @extend .fw-bold;
    @extend .ms-1;
}

.col.event-reg-sidebar.tab-content {
    z-index: 0;
}

.event-reg-sidebar .tab-pane {
    min-height: 20px;
}

.viewing-window {
    padding-top: 5px;
    background-color: $gray-400;
    max-height: 50vh;
    overflow-y: scroll;
}

.viewing-window-modal {
    @extend .viewing-window;
    max-height: 20vh;
}

.table-responsive {
    max-height: 33vh;
    overflow-y: scroll;
}

.input-warning {
    background-color: #fdfd64;
}
.input-warning:focus {
    background-color: #fdfd64;
}
  