@use "sass:map";

@import "../../node_modules/bootstrap/scss/functions.scss";
@import "../../node_modules/bootstrap/scss/variables.scss";

$theme-colors: (
    "primary": $gray-800,
    "secondary": $gray-700,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "dark": $dark
);

@import "../../node_modules/bootstrap/scss/bootstrap.scss";

body {
    background-color: #000 !important;
    color: #fff !important;
}

button.btn.btn-badge {
    opacity: 1;
}

input[type=text],
input[type=email],
input[type=password],
input[type=number],
select {
    max-width: 400px;
}

input[type=number] {
    width: 100px;
}

div.full-page {
    @extend .rounded;
    @extend .m-sm-3;
    height: calc(100vh - 3 * $spacer);
    background-color: $gray-500;
    color: #000;
}

div.modal-content {
    color: #000;
}

div.table-responsive {
    overflow-y: auto;
    flex-grow: 1;
    flex-basis: 500px;
}

table.table {
    width: auto;
}

td.center-align-column,
th.center-align-column {
    text-align: center;
}

.btn.back-button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1;
}

@media print {
    .print-character-sheet {
        color: #000;
    }

    .dont-print {
        display: none;
    }

    .back-button {
        @extend .dont-print;
    }
}